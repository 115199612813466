
import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from "react-reveal/Fade";

import case1 from '../../img/AccountTypes/case.png'
import stars from '../../img/AccountTypes/stars.png'
import cypola from '../../img/AccountTypes/cypola.png'
import money from '../../img/AccountTypes/money.png'

function Accounts () {

    const { t } = useTranslation();

    return(
        <div className="relative overflow-hidden">
            <div className="relative pt-[80px] xl:pt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="flex flex-col xl:flex-row gap-[20px]">
                    <Fade left delay={1000}>
                        <div className="w-full h-[841px] bg-white rounded-[40px] relative overflow-hidden">
                            <div className="flex gap-[15px] xl:gap-[20px] items-center mt-[60px]">
                                <div className="bg-[#00D1FF] w-[10px] xl:w-[80px] h-[8px]"></div>
                                <p className="bebas leading-[1.2] text-[20px] xl:text-[40px] uppercase whitespace-nowrap">{t('accounttypes.6')}</p>
                                <div className="bg-[#00D1FF] w-full h-[8px]"></div>
                            </div>
                            <p className="mx-[20px] xl:mx-[60px] mt-[20px] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.7')}</p>
                            <p className="text-[16px] xl:text-[20px] roboto mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-[60px]">{t('accounttypes.8')}</p>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[20px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.9')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.10')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.11')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.12')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.13')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.14')}</p>
                            </div>
                            <p className="text-[16px] xl:text-[20px] roboto mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-[60px]">{t('accounttypes.15')}</p>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[20px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.16')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.17')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.18')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.19')}</p>
                            </div>
                            <a href="https://user.ameno-platform.org/login">
                                <div className="button_main mx-[20px] xl:mx-[60px] absolute bottom-[10px]">
                                    <p className="text-white text-[16px] xl:text-[20px] font-[500] uppercase roboto">{t('accounttypes.20')}</p>
                                </div>
                            </a>
                            <Fade delay={1200} right><img src={case1} alt="" className="hidden xl:block absolute right-0 bottom-0"/></Fade>
                        </div>
                    </Fade>
                    <Fade right delay={1000}>
                        <div className="w-full h-[841px] bg-white rounded-[40px] relative overflow-hidden">
                            <div className="flex gap-[15px] xl:gap-[20px] items-center mt-[60px]">
                                <div className="bg-[#00D1FF] w-[10px] xl:w-[80px] h-[8px]"></div>
                                <p className="bebas leading-[1.2] text-[20px] xl:text-[40px] uppercase whitespace-nowrap">{t('accounttypes.21')}</p>
                                <div className="bg-[#00D1FF] w-full h-[8px]"></div>
                            </div>
                            <p className="mx-[20px] xl:mx-[60px] mt-[20px] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.22')}</p>
                            <p className="text-[16px] xl:text-[20px] roboto mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-[60px]">{t('accounttypes.23')}</p>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[20px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.24')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.25')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.26')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.27')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.28')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.29')}</p>
                            </div>
                            <p className="text-[16px] xl:text-[20px] roboto mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-[60px]">{t('accounttypes.30')}</p>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[20px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.31')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.32')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.33')}</p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-center">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('accounttypes.34')}</p>
                            </div>
                            <a href="https://user.ameno-platform.org/login">
                                <div className="button_main mx-[20px] xl:mx-[60px] absolute bottom-[10px]">
                                    <p className="text-white text-[16px] xl:text-[20px] font-[500] uppercase roboto">{t('accounttypes.20')}</p>
                                </div>
                            </a>
                            <Fade delay={1200} right><img src={stars} alt="" className="hidden xl:block absolute right-0 bottom-0"/></Fade>
                        </div>
                    </Fade>
                </div>
                <div className="w-full bg-white rounded-[40px] h-[750px] xl:h-[650px] mt-[20px] relative">
                    <div className="flex flex-col xl:flex-row justify-between pt-[40px] xl:pt-[80px]">
                        <div>
                            <div className="flex items-center gap-[20px] xl:mr-[100px]">
                                <div className="bg-[#00D1FF] w-[10px] xl:w-[40px] h-[8px] mt-[10px]"></div>
                                <p className="bebas text-[25px] xl:text-[64px] uppercase whitespace-nowrap">{t('accounttypes.35')}</p>
                            </div>
                            <p className=" mx-[20px] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[494px] xl:hidden block">{t('accounttypes.36')}</p>
                            <p className="text-[16px] xl:text-[20px] roboto mt-[20px] xl:mt-[60px] mx-[20px] xl:mx-[60px]">{t('accounttypes.37')}</p>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[20px] gap-[16px] items-start">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.38')}</Trans></p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-start">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.39')}</Trans></p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-start">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.40')}</Trans></p>
                            </div>
                            <a href="https://user.ameno-platform.org/login">
                                <div className="button_main mx-[20px] xl:mx-[60px] absolute bottom-[10px]">
                                    <p className="text-white text-[16px] xl:text-[20px] font-[500] uppercase roboto">{t('accounttypes.20')}</p>
                                </div>
                            </a>
                        </div>
                        <div className="mx-[20px] xl:mr-[100px]">
                            <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[494px] hidden xl:block">{t('accounttypes.36')}</p>
                            <div className="flex gap-[16px] items-start mt-[12px] xl:mt-[100px]">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.41')}</Trans></p>
                            </div>
                            <div className="flex gap-[16px] items-start mt-[12px] z-50 relative">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.42')}</Trans></p>
                            </div>
                            <img src={cypola} alt="" className="absolute right-0 bottom-0 hidden xl:block"/>
                        </div>
                    </div>
                </div>
                <div className="w-full bg-white rounded-[40px] h-[1050px] xl:h-[759px] mt-[20px] relative">
                    <div className="flex flex-col xl:flex-row justify-between pt-[40px] xl:pt-[60px]">
                        <div>
                            <div className="flex items-center gap-[20px] xl:mr-[100px]">
                                <div className="bg-[#00D1FF] w-[10px] xl:w-[40px] h-[8px] mt-[10px]"></div>
                                <p className="bebas text-[25px] xl:text-[64px] uppercase whitespace-nowrap">{t('accounttypes.43')}</p>
                            </div>
                            <p className="mx-[20px] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[494px] xl:hidden block">{t('accounttypes.44')}</p>
                            <p className="text-[16px] xl:text-[20px] roboto mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-[60px]">{t('accounttypes.45')}</p>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[20px] gap-[16px] items-start">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.46')}</Trans></p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-start">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.47')}</Trans></p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-start">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.48')}</Trans></p>
                            </div>
                            <div className="flex mx-[20px] xl:mx-[60px] mt-[12px] gap-[16px] items-start">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.49')}</Trans></p>
                            </div>
                            <a href="https://user.ameno-platform.org/login">
                                <div className="button_main mx-[20px] xl:mx-[60px] absolute bottom-[10px]">
                                    <p className="text-white text-[16px] xl:text-[20px] font-[500] uppercase roboto">{t('accounttypes.20')}</p>
                                </div>
                            </a>
                        </div>
                        <div className="mx-[20px] xl:mr-[100px]">
                            <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[494px] hidden xl:block">{t('accounttypes.44')}</p>
                            <div className="flex gap-[16px] items-start mt-[12px] xl:mt-[100px]">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.50')}</Trans></p>
                            </div>
                            <div className="flex gap-[16px] items-start mt-[12px] z-50 relative">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.51')}</Trans></p>
                            </div>
                            <div className="flex gap-[16px] items-start mt-[12px] z-50 relative">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.52')}</Trans></p>
                            </div>
                            <div className="flex gap-[16px] items-start mt-[12px] z-50 relative">
                                <div className="w-[8px] h-[8px] rounded-[2px] bg-[#CECECE] flex-shrink-0 mt-[8px]"></div>
                                <p className="font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[424px] spandiscover"><Trans>{t('accounttypes.53')}</Trans></p>
                            </div>
                            <img src={money} alt="" className="hidden xl:block absolute right-0 bottom-0"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Accounts;