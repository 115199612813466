import React from "react";

import { useTranslation } from "react-i18next";

import m1 from '../../img/Banking/m1.png'
import m2 from '../../img/Banking/m2.png'
import m3 from '../../img/Banking/m3.png'

function Main () {

    const { t } = useTranslation();
 
    return(
        <div className="overflow-hidden relative pt-[160px]">
            <p className="bebas text-[40px] xl:text-[90px] text-center">{t('banking.1')}</p>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between mt-[40px] xl:mt-[80px]">
                <div className="h-[280px] xl:w-[497px] rounded-[40px] overflow-hidden">
                    <img src={m1} alt="" className="w-full h-full object-cover"/>
                </div>
                <div className="h-[280px] xl:w-[766px] bg-white rounded-[40px] flex flex-col justify-center mt-[20px] xl:mt-0">
                    <p className="roboto text-[20px] xl:text-[32px] xl:ml-[100px] mx-[20px] xl:mx-0">{t('banking.2')}</p>  
                    <p className="max-w-[313px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[12px] font-[300] xl:ml-[100px] mx-[20px] xl:mx-0">{t('banking.3')}</p>    
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row-reverse justify-between mt-[20px]">
                <div className="h-[280px] xl:w-[497px] rounded-[40px] overflow-hidden">
                    <img src={m2} alt="" className="w-full h-full object-cover"/>
                </div>
                <div className="h-[280px] xl:w-[766px] bg-[#CECECE] rounded-[40px] flex flex-col justify-center mt-[20px] xl:mt-0">
                    <p className="roboto text-[20px] xl:text-[32px] xl:ml-[100px] mx-[20px] xl:mx-0">{t('banking.4')}</p>  
                    <p className="max-w-[313px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[12px] font-[300] xl:ml-[100px] mx-[20px] xl:mx-0">{t('banking.5')}</p>    
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between mt-[20px]">
                <div className="h-[280px] xl:w-[497px] rounded-[40px] overflow-hidden">
                    <img src={m3} alt="" className="w-full h-full object-cover"/>
                </div>
                <div className="h-[280px] xl:w-[766px] bg-[#131313] rounded-[40px] flex flex-col justify-center mt-[20px] xl:mt-0">
                    <p className="roboto text-[20px] xl:text-[32px] xl:ml-[100px] mx-[20px] xl:mx-0 text-[#FFF]">{t('banking.6')}</p>  
                    <p className="max-w-[313px] text-[#FFF] text-[14px] xl:text-[16px] mt-[12px] font-[300] xl:ml-[100px] mx-[20px] xl:mx-0">{t('banking.7')}</p>    
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex">
                <a href="https://user.ameno-platform.org/login">
                    <div className="button_main mt-[40px] ml-auto">
                        <p className="text-[14px] xl:text-[20px] roboto uppercase font-[500] text-white">{t('main.3')}</p>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Main;