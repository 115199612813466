
import React from "react";

import { useTranslation, Trans } from "react-i18next";

import be1 from '../../img/Investing/be1.png'
import be2 from '../../img/Investing/be2.png'
import be3 from '../../img/Investing/be3.png'
import be4 from '../../img/Investing/be4.png'

import Fade from "react-reveal/Fade";

function BeInvestor () {

    const { t } = useTranslation();
 
    return(
        <div className="relative flex flex-col max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-0">
            <div className="flex flex-col xl:flex-row justify-between">
                <Fade left><div className="xl:w-[703px] xl:h-[647px] rounded-[40px] bg-white">
                    <div className="mx-[20px] xl:mx-[40px] mt-[60px]">
                        <div className="w-[312px] h-[1px] bg-[#606060]"></div>
                        <p className="roboto text-[24px] xl:text-[40px]"><Trans>{t('investing.12')}</Trans></p>
                        <p className="roboto text-[16px] xl:text-[24px]">{t('investing.13')}</p>
                        <img src={be1} alt="" className="mt-[-50px] xl:mt-[-100px]"/>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] mt-[40px] font-[300] pb-[20px] xl:pb-0">{t('investing.14')}</p>
                    </div>
                </div></Fade>
                <Fade right delay={700}><div className="mt-[20px] xl:mt-0 xl:w-[557px] xl:h-[647px] rounded-[40px] bg-white flex flex-col justify-between">
                    <p className="roboto text-[24px] xl:text-[40px] mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-[60px]">{t('investing.15')}</p>
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300] mx-[20px] mt-[20px] xl:mt-0 xl:mx-[40px] pb-[20px] xl:pb-[40px]">{t('investing.16')}</p>
                </div></Fade>
            </div>
            <Fade><div className="xl:h-[255px] rounded-[40px] bg-white mt-[20px] flex flex-col xl:flex-row justify-between">
                <div className="flex flex-col justify-between xl:h-[220px]">
                    <p className="roboto text-[24px] xl:text-[40px] mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-[40px]">{t('investing.17')}</p>
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300] mt-[20px] xl:mt-0 mx-[20px] xl:mx-[40px] max-w-[600px]">{t('investing.18')}</p>
                </div>
                <img src={be2} alt="" className="xl:my-auto mx-[20px] xl:mx-[40px] mt-[20px] pb-[20px] xl:pb-0"/>
            </div></Fade>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px]">
                <Fade left><div className="xl:w-[497px] xl:h-[547px] rounded-[40px] bg-white">
                    <div className="mx-[20px] xl:mx-[40px] mt-[60px]">
                        <div className="w-[175px] h-[1px] bg-[#606060]"></div>
                        <p className="roboto text-[24px] xl:text-[30px] mt-[5px]">{t('investing.19')}</p>
                        <img src={be3} alt="" className="mt-[-20px] xl:mt-[-80px]"/>
                        <p className="roboto text-[16px] xl:text-[24px] mt-[40px] max-w-[294px]">{t('investing.20')}</p>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300] mt-[20px] pb-[20px] xl:pb-0">{t('investing.21')}</p>
                    </div>
                </div></Fade>
                <Fade delay={700} right><div className="xl:w-[763px] xl:h-[547px] rounded-[40px] bg-white flex flex-col justify-between mt-[20px] xl:mt-0">
                    <p className="roboto text-[24px] xl:text-[40px] mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-[60px]">{t('investing.22')}</p>
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300] mx-[20px] xl:mx-[40px] mt-[20px] pb-[20px] xl:pb-[40px]">{t('investing.23')}</p>
                </div></Fade>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px]">
                <Fade left><div className="xl:w-[703px] xl:h-[547px] rounded-[40px] bg-white">
                    <div className="mx-[20px] xl:mx-[40px] mt-[60px]">
                        <div className="w-[342px] h-[1px] bg-[#606060]"></div>
                        <p className="roboto text-[24px] xl:text-[40px] max-w-[150px] xl:max-w-[294px]">{t('investing.24')}</p>
                        <img src={be4} alt="" className="mt-[-70px] xl:mt-[-120px]"/>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] mt-[20px] xl:mt-[40px] font-[300] pb-[20px] xl:pb-0">{t('investing.25')}</p>
                    </div>
                </div></Fade>
                <Fade delay={700} right><div className="xl:w-[557px] xl:h-[547px] rounded-[40px] bg-white flex flex-col justify-between mt-[20px] xl:mt-0">
                    <p className="roboto text-[24px] xl:text-[40px] mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-[60px]">{t('investing.26')}</p>
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300] mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-0 pb-[20px] xl:pb-[40px]">{t('investing.27')}</p>
                </div></Fade>
            </div>
        </div>
    );
}

export default BeInvestor;