import React, { useEffect } from "react";

import Main from '../components/ReferralProgram/Main'
import Earn from "../components/ReferralProgram/Earn";
import TradeZenReferral from "../components/ReferralProgram/TradeZenReferral";
import HowDoesYour from "../components/ReferralProgram/HowDoesYour";
import AdvantagesofReferral from "../components/ReferralProgram/AdvantagesofReferral";
import Ready from "../components/Home/Ready";

function ReferralProgram () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#E8E8E8]">
            <Main/>
            <Earn/>
            <TradeZenReferral/>
            <HowDoesYour/>
            <AdvantagesofReferral/>
            <Ready/>
        </div>
    );
}

export default ReferralProgram;