import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import button from '../../img/Home/button.png'

const faqData = [
    {
        question: 'main.48',
        answer: 'main.49'
    },
    {
        question: 'main.50',
        answer: 'main.51'
    },
    {
        question: 'main.52',
        answer: 'main.53'
    },
    {
        question: 'main.54',
        answer: 'main.55'
    },
    {
        question: 'main.56',
        answer: 'main.57'
    },
]

function FAQs () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(1);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
 
    return(
        <div className="mt-[80px] xl:mt-[160px] overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between">
                <div>
                    <p className="roboto text-[24px] xl:text-[40px]">{t('main.47')}</p>
                </div>
                <div className="w-[703px]">
                    {faqData.map((faq, index) => (
                        <div key={index}>
                          <div className={`bg-[#FFF] flex mt-[10px] transition-all duration-300 border ${openIndex === index ? 'h-auto rounded-[20px]' : 'h-[65px] items-center rounded-[40px]'}`}>
                              <div className={`cursor-pointer w-[42px] h-[42px] bg-[#CECECE] rounded-full flex items-center justify-center ml-[5px] ${openIndex === index ? 'mt-[5px]' : 'mt-0'}`} onClick={() => toggleText(index)}>
                                  <img src={button} alt="" className={`${openIndex === index ? 'transition-transform rotate-45' : 'transition-transform'}`} />
                              </div>
                              <div>
                                  <p className={`max-w-[200px] xl:max-w-max ml-[26px] inter text-[14px] xl:text-[16px] text-explore ${openIndex === index ? 'mt-[15px]' : 'mt-0'}`}>{t(faq.question)}</p>
                                  {openIndex === index && (
                                      <p className='ml-[26px] inter text-[#000000B2] font-light text-[14px] xl:text-[16px] mt-[10px] pb-[10px] max-w-[250px] xl:max-w-[600px]'><Trans>{t(faq.answer)}</Trans></p>
                                  )}
                              </div>
                          </div>
                      </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FAQs;