import React from "react";

import { useTranslation, Trans } from "react-i18next";

import check from '../../img/Crypto/check.png'

function TradeZenReferral () {

    const { t } = useTranslation();
 
    return(
        <div className="relative mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
                <div className="flex flex-col xl:flex-row gap-[15px]">
                    <p className="text-[32px] xl:text-[40px] leading-[1.2] roboto">{t('referralprogram.12')}</p>
                    <p className="text-[32px] xl:text-[40px] leading-[1.2] roboto font-[300]">{t('referralprogram.13')}</p>
                </div>
                <div className="flex flex-col xl:flex-row justify-between mt-[30px] xl:mt-[60px]">
                    <div className="flex flex-col justify-between">
                        <div className="flex gap-[30px] items-center">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px]">{t('referralprogram.14')}</p>
                        </div>
                        <div className="flex gap-[30px] items-center mt-[25px]">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px]">{t('referralprogram.15')}</p>
                        </div>
                        <div className="flex gap-[30px] items-center mt-[25px]">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px]">{t('referralprogram.16')}</p>
                        </div>
                        <div className="flex gap-[30px] items-center mt-[25px]">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px]">{t('referralprogram.17')}</p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between mt-[25px] xl:mt-0">
                        <p className="text-[#000000B2] roboto text-[16px] xl:text-[20px] font-[500]">{t('referralprogram.18')}</p>
                        <div className="flex gap-[30px] items-center mt-[25px]">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px]">{t('referralprogram.19')}</p>
                        </div>
                        <div className="flex gap-[30px] items-center mt-[25px]">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px]">{t('referralprogram.20')}</p>
                        </div>
                        <div className="flex gap-[30px] items-center mt-[25px]">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px]">{t('referralprogram.21')}</p>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
}

export default TradeZenReferral;