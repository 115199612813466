import React, { useEffect } from "react";

import Main from '../components/Iras/Main'
import Discover from "../components/Iras/Discover";
import Active from "../components/Iras/Active";
import Faq from "../components/Iras/FaqI";

function Iras () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#E8E8E8]">
            <Main/>
            <Discover/>
            <Active/>
            <Faq/>
        </div>
    );
}

export default Iras;