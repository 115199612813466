import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import Whydoover from "../components/Home/Whydoover";
import TradeZen from "../components/Home/TradeZen";
import Allinone from "../components/Home/All-in-one";
import Ready from "../components/Home/Ready";
import FAQs from "../components/Home/FAQs";
import Our from "../components/Home/Our";

function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#E8E8E8]">
            <Main/>
            <Whydoover/>
            <TradeZen/>
            <Allinone/>
            <Ready/>
            <FAQs/>
            <Our/>
        </div>
    );
}

export default Home;