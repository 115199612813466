
import React from "react";

import { useTranslation } from "react-i18next";

import main from '../../img/AccountTypes/main.png'

import m1 from '../../img/AccountTypes/m1.png'
import m2 from '../../img/AccountTypes/m2.png'
import m3 from '../../img/AccountTypes/m3.png'

import Fade from "react-reveal/Fade";

function Main () {

    const { t } = useTranslation();

    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div>
                    <p className="text-[24px] xl:text-[32px] roboto">{t('accounttypes.1')}</p>
                    <p className="text-[32px] xl:text-[64px] bebas max-w-[600px] leading-[1.2] mt-[6px]">{t('accounttypes.2')}</p>
                    <p className="roboto text-[#000000B2] max-w-[600px] font-[300] mt-[20px]">{t('accounttypes.3')}</p>
                    <p className="roboto text-[#000000B2] max-w-[600px] font-[300] mt-[20px]">{t('accounttypes.4')}</p>
                    <p className="roboto text-[#000000B2] max-w-[600px] font-[300] mt-[20px]">{t('accounttypes.5')}</p>
                </div>
                <div className="relative mt-[50px] xl:mt-0">
                    <Fade right delay={300}><img src={m1} alt=""  className="absolute z-50"/></Fade>
                    <Fade right delay={500}><img src={m2} alt=""  className="absolute top-[150px] left-[20px] xl:left-[-90px] z-50"/></Fade>
                    <Fade right delay={700}><img src={m3} alt=""  className="absolute top-[210px] xl:top-[230px] left-[50px] xl:left-[-90px] z-50"/></Fade>
                    <Fade><img src={main} alt="" /></Fade>
                </div>
            </div>
        </div>
    );
}

export default Main;